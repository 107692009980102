<template>
  <div class="fullScreen relative clearfix">
    <!-- FILTER -->
    <!-- Filter Icon -->
    <feather-icon
      class="inline-flex lg:hidden cursor-pointer mr-4"
      icon="MenuIcon"
      @click.stop="toggleFilterSidebar"
    />
    <!-- Sidebar -->
    <vs-sidebar
      class="items-no-padding vs-sidebar-rounded background-absolute"
      :click-not-close="clickNotClose"
      :hidden-background="clickNotClose"
      v-model="isFilterSidebarActive"
    >
      <!-- Left Coloum -->
      <div class="p-6">
        <!-- p-6 Margin Setting -->
        <h2 class="mb-6">{{ $t("Filter") }}</h2>
        <div>
          <ul>
            <li v-show="logs.lenght == 0">{{ $t("No_Result") }}</li>
            <hr />
            <br />
            <div>
              <li class="mb-4 flex items-center justify-between">
                <p class="ml-0 font-bold">{{ $t("Machines") }}</p>
              </li>
            </div>
            <li
              class="mb-2 flex items-center justify-between"
              :key="machine.id"
              v-for="machine in machines"
            >
              <vs-checkbox
                @input="getPrintLogs()"
                class="ml-0"
                :vs-value="machine.id"
                v-model="filteredMachines"
              >
                {{
                  fixMachineTypeAndSerial(
                    machine.machine_type,
                    machine.serial_display
                  )
                }}
              </vs-checkbox>
            </li>
            <br />
            <hr />
            <br />
            <!-- DATE FİLTER -->
            <div>
              <li class="mb-4 flex items-center justify-between">
                <p class="ml-0 font-bold">{{ $t("Date") }}</p>
              </li>
            </div>
            <li class="mb-3 flex items-center justify-between">
              <vs-radio
                v-model="picked_date"
                @input="filterWithTime(1)"
                vs-value="today"
                >{{ $t("Today") }}</vs-radio
              >
            </li>
            <li class="mb-3 flex items-center justify-between">
              <vs-radio
                v-model="picked_date"
                vs-value="lastWeek"
                @input="filterWithTime(7)"
                >{{ $t("Last_Week") }}</vs-radio
              >
            </li>
            <li class="mb-3 flex items-center justify-between">
              <vs-radio
                v-model="picked_date"
                vs-value="lastMonth"
                @input="filterWithTime(30)"
                >{{ $t("Last_Month") }}</vs-radio
              >
            </li>
            <li class="mb-3 flex items-center justify-between">
              <vs-radio
                v-model="picked_date"
                vs-value="allTime"
                @input="filterWithTime(0)"
                >{{ $t("All_Time") }}</vs-radio
              >
            </li>
            <br />
            <hr />
          </ul>
          <br />
          <!-- TIME PICKER -->
          <flat-pickr
            :config="configFromdateTimePicker"
            v-model="fromDate"
            :placeholder="$t('From_Date')"
            @on-change="onFromChange"
          />
          <br />
          <br />
          <flat-pickr
            :config="configTodateTimePicker"
            v-model="toDate"
            :placeholder="$t('To_Date')"
            @on-change="onToChange"
          />
          <vs-col class="mt-2" vs-type="flex" vs-justify="center">
            <vs-button @click="filterWithTimeBeforeAfter">{{
              $t("Date_Filter")
            }}</vs-button>
          </vs-col>
        </div>
      </div>
    </vs-sidebar>

    <!-- RIGHT COL -->
    <div :class="{ 'sidebar-spacer-with-margin ': clickNotClose }">
      <!-- PRINT LOGS -->
      <vs-row vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-input
          class="searchAndSort ml-2 pt-2 pb-2 w-full"
          icon="search"
          icon-no-border
          size="medium"
          v-model="searchValue"
          @keypress.enter="searchOrder()"
        />
      </vs-row>
      <vx-card class="pringLogsList">
        <vs-table stripe :data="logs" @change-page="handleChangePage">
          <!-- TABLE HEADERS -->
          <template slot="thead">
            <vs-th class="vs-th">{{ $t("Machine").toUpperCase() }}</vs-th>
            <vs-th class="vs-th">{{ $t("Plate_Name").toUpperCase() }}</vs-th>
            <vs-th class="vs-th">{{ $t("Profile").toUpperCase() }}</vs-th>
            <vs-th class="vs-th">{{
              $t("Print_Start_Time").toUpperCase()
            }}</vs-th>
            <vs-th class="vs-th">{{ $t("Print_Time").toUpperCase() }}</vs-th>
            <vs-th class="vs-th">{{ $t("Layers").toUpperCase() }}</vs-th>
            <vs-th class="vs-th">{{ $t("Solid_Area").toUpperCase() }}</vs-th>
            <vs-th class="vs-th">{{ $t("Status").toUpperCase() }}</vs-th>
          </template>
          <!-- TABLE DATAS -->
          <template slot-scope="{ data }">
            <vs-tr
              :data="printLog"
              :key="index"
              v-for="(printLog, index) in data"
            >
              <vs-td :data="data[index].machine">
                {{
                  fixMachineTypeAndSerial(
                    data[index].machine_type,
                    data[index].serial_display
                  )
                }}
              </vs-td>

              <vs-td :data="data[index].model_name">
                <p class="longText">{{ data[index].model_name }}</p>
              </vs-td>

              <vs-td :data="data[index].profile_name">
                <p class="longTextProfile">
                  %{{ calculateExposure(data[index].exposure).toFixed(0) }}
                  {{ data[index].profile_name }}
                </p>
              </vs-td>

              <vs-td class="text-center" :data="data[index].print_start">
                <p class="longText">
                  {{ getRelativeTime(data[index].print_start) }}
                </p></vs-td
              >

              <vs-td :data="data[index].printing_time"
                >{{ data[index].printing_time }} min</vs-td
              >

              <vs-td :data="data[index].layer_count">
                {{ data[index].layer_count }}
              </vs-td>

              <vs-td :data="data[index].total_solid_area">
                {{ data[index].total_solid_area.toFixed(1) }}
              </vs-td>

              <vs-td :data="data[index].print_status">
                {{ data[index].print_status }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
      <div class="m-2">
        <vs-pagination
          :total="totalPage"
          v-model="currentPage"
          @change="handleChangePage(currentPage)"
        />
      </div>
    </div>
  </div>
</template>

<script>
//Date Picker
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

//API
import Api from "@/api/api";
import API from "@/api/config";

import moment from "moment";

export default {
  data() {
    return {
      searchValue: "",
      searchActive: true,

      //Filter
      machines: [],
      filteredMachines: [],

      print_start_before: null,
      print_start_after: null,

      fromToDateActive: false,
      beforeAfterDateActive: false,

      //Pagination
      currentPage: 1,
      totalPage: 1,

      //Date Picker
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },

      // Filter Sidebar
      isFilterSidebarActive: true,
      clickNotClose: true,

      picked_date: null,

      //Data
      logs: [],
    };
  },
  components: {
    flatPickr,
  },
  watch: {
    windowWidth() {
      this.setSidebarWidth();
    },
  },
  methods: {
    getRelativeTime(value) {
      return moment(String(value)).format("DD/MM/YY - HH:mm");
    },
    searchOrder() {
      if (this.searchActive) {
        this.searchActive = false;
        this.currentPage = 1;
        this.getPrintLogs();
      }
    },
    calculateExposure(exp) {
      if (exp < 80) {
        return exp * 100;
      } else if (exp == 0) {
        return 100;
      } else {
        return exp;
      }
    },
    fixMachineTypeAndSerial(type, serial) {
      if (type) {
        return type + " - " + serial;
      } else {
        return serial;
      }
    },
    filterWithTimeBeforeAfter() {
      if (this.toDate != null || this.fromDate != null) {
        this.fromToDateActive = true;
        this.getPrintLogs();
      } else {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Machine_Message_2"),
          color: "warning",
        });
      }
    },
    filterWithTime(after) {
      this.beforeAfterDateActive = true;
      this.print_start_after = null;

      const today = new Date();
      if (after == 0) {
        const date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();

        const dateTime = date;

        this.print_start_before = dateTime;
        this.print_start_after = null;
      } else if (after != null) {
        today.setDate(today.getDate() - after);

        const date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();

        const dateTime = date;

        this.print_start_after = dateTime;
        this.print_start_before = null;
      }
      this.getPrintLogs();
    },
    getPrintLogs() {
      var params = {};

      this.filteredMachines.forEach((element) => {
        if (params.machine) {
          params.machine += "," + String(element);
        } else {
          params.machine = String(element);
        }
      });

      if (this.fromToDateActive) {
        this.fromToDateActive = false;

        params.print_start_after = this.fromDate;
        params.print_start_before = this.toDate;

        this.print_start_after = null;
        this.print_start_before = null;
        this.picked_date = null;
      } else if (this.beforeAfterDateActive) {
        this.beforeAfterDateActive = false;

        if (this.print_start_after != null) {
          params.print_start_after = this.print_start_after;
        } else {
          params.print_start_before = this.print_start_before;
        }
        this.toDate = null;
        this.fromDate = null;
      }

      if (this.searchValue != "") {
        params.search = this.searchValue;
      }

      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      if (this.currentPage != 1) {
        params.page = this.currentPage;
      }

      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });

      Api.get(API.BASEURL + API.PRINTLOGS, this.handleGetLogsRequest, params);
    },
    handleMachinesRequest(status, data) {
      this.$vs.loading.close();
      if (status == 200) {
        this.machines = data;
      }
    },

    getMachinesRequest() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      Api.get(API.BASEURL + API.PRINTLOGSFILTER, this.handleMachinesRequest);
    },

    handleGetLogsRequest(status, data) {
      this.searchActive = true;

      this.$vs.loading.close();
      if (status == 200) {
        this.logs = data.results;
        this.totalPage = Math.ceil(data.count / 50);
      } else if (status == 0) {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Internet_Connection_Message"),
          color: "danger",
        });
      }
    },
    //Change Page
    handleChangePage(page) {
      this.currentPage = page;

      this.getPrintLogs();
    },
    //Date Functions
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },

    //First Filter Control
    controlRouter() {
      if (this.$route.query.machine) {
        var machines = this.$route.query.machine.split(",");

        if (machines.length > 1) {
          machines.forEach((element) => {
            this.filteredMachines.push(parseInt(element));
          });
        } else if (machines.length == 1) {
          this.filteredMachines.push(parseInt(machines[0]));
        }
      }
      if (this.$route.query.search) {
        this.searchValue = this.$route.query.search;
      }
    },

    //Control Window
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isFilterSidebarActive = this.clickNotClose = false;
      } else {
        this.isFilterSidebarActive = this.clickNotClose = true;
      }
    },
    toggleFilterSidebar() {
      if (this.clickNotClose) return;
      this.isFilterSidebarActive = !this.isFilterSidebarActive;
    },

    //Filter
    filterItems(items, selectedMachines) {
      if (selectedMachines[0] == null) {
        return items;
      }
      return items.filter(function (item) {
        return selectedMachines.includes(item.machine);
      });
    },
  },

  computed: {
    // Window Function
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    //Filtered Machine IDs
    filteredMachineIds() {
      var ret = {};
      for (let i in this.logs) {
        let key = this.logs[i].machine;
        ret[key] = {
          machineId: key,
          count: ret[key] && ret[key].count ? ret[key].count + 1 : 1,
        };
      }
      return Object.values(ret);
    },
  },

  created() {
    if (this.$route.query.page != null && this.$route.query.page != "1") {
      this.currentPage = parseInt(this.$route.query.page);
    }

    this.controlRouter();
    this.setSidebarWidth();
  },
  beforeMount() {
    this.getMachinesRequest();
  },
};
</script>

<style scoped>
@media (min-width: 601px) {
  .searchAndSort {
    min-width: 220px;
    max-width: 220px;
  }
}
@media (max-width: 601px) {
  .searchAndSort {
    min-width: auto;
    max-width: auto;
  }
}
@media (max-width: 651px) {
  .searchAndSort {
    min-width: 150px;
    max-width: 200px;
  }
}
</style>

<style lang="scss">
.totalSolidArea {
  text-align: center;
}
.vs-input--icon {
  font-size: 1.5rem;
}
.longTextProfile {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.longTextProfile:hover {
  overflow: visible;
}

.longText {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.longText:hover {
  overflow: visible;
}

.fullScreen {
  height: 100%;
  min-height: 100vh;
}
.pringLogsList {
  justify-content: center;
}
.vs-th {
  color: #c89e82;
}
.vs-td {
  text-align: center;
}

.vs-con-table {
  .vs-table--pagination {
    justify-content: center;
  }
}
#printlogs {
  .vs-sidebar {
    position: relative;
    float: left;
  }
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #printlogs {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}
</style>
